import {  Flex, Image, Stack, Text,Icon,colorMode, toggleColorMode, useColorMode, Input, HStack, PinInput, PinInputField, Spinner} from "@chakra-ui/react"; 
import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
import { LinkK } from "../../../components/button/LinkKalyst";
import { InputK } from "../../../components/input/InputKalyst";
import Typewriter from 'typewriter-effect';
import {   HK3, HK4, HK5 } from "../../../components/heading/HeadingKalyst";
import { SendForm } from "../../../components/form/SendFormAdmin";
import { number, object,string } from 'yup';
import { Helmet } from 'react-helmet-async';
import { TextK1 } from "../../../components/text/TextKalyst";
import { FaBeer } from "react-icons/fa"; 
import BoxDialog from "../../../components/modal/BoxDialog";
import { setSession } from "../../../components/session/SessionKalyst";
import { Navigate, Route, useLocation } from "react-router-dom";
import { useState } from "react";
import { getWebsite } from "../../../theme/PageConfig";
import LoadBg from "../../../components/backgound/LoadBg";
import { sendFormSocket } from "../../../components/session/SessionAdmin";
import { TempoCronometro } from "../../../components/Function";
import { LuTrash } from "react-icons/lu";
export function PageAdminLost(page){
 
    const { colorMode, toggleColorMode } = useColorMode();
    const [email,setEmail] = useState('');
    const [code,setCode] = useState('');
    const [cmd,setCmd] = useState('check'); 
    const [redirect,setRedirect] = useState(null); 
    
    const [edit,setEdit]    = useState({}); 
    const [alert,setAlert]    = useState({}); 
    const location = useLocation();  
    var pag=[];
    (location.pathname+'/').replace('//','/').split('/').forEach((path)=>{
        if(path!==''){
            pag.push(path);
        }
    });  
    if(typeof pag[2]=='undefined'){ 
        return <Navigate to={'/admin/login'} />; 
    }else{ 
        var codeURL = pag[2];
        let view = null;
        switch (cmd) {
            case 'change':
           
                view = (
                    <SendForm variant="admin" variantBT='admin'  dados = {{modulo:'user',cmd:'changePassword',form:{email,chave:codeURL}}}
                        initial = {
                            {
                                senha1       :   '',
                                senha2       :   '' 
                            }
                        } 
                        schema={
                            object({ 
                                senha1:      string().required('Nova Senha é obrigatório!'),
                                senha2:      string().required('Confirmar Senha é obrigatório!') 
                            })
                        } 
                        callback={(result)=>{   
                            setRedirect(<Navigate to={'/admin/login'} />); 
                        }}
                        gap={'15px'}
                        > 
                              
                        <HK4 pb={{base:'0px',md:'0px',lg:'0px'}} textAlign='center'>Gerar nova Senha</HK4>  

                        <Text w={'100%'}  color='textAdmin.300' textAlign={'center'} noOfLines={1}>{code.login}</Text> 
                        <InputK type='password'  label="Nova Senha"         name="senha1"  placeholder="Digite sua nova senha"   variant='admin' variantBT="admin" autoComplete={false}/> 
                        <InputK type='password'  label="Confirmar Senha"    name="senha2"  placeholder="Confirmar sua nova senha"   variant='admin' variantBT="admin" autoComplete={false}/> 
                        <ButtonKalyst w='full' variant='admin'>
                            Salvar Nova Senha
                        </ButtonKalyst>
                        <Flex w={'full'} align={'center'} justify='center' gap={'10px'}>
                            <TextK1>Expira em <Text as={'span'} color={'textAdmin.300'}><TempoCronometro asc='desc' admin={true} time={code.expira} view = 'tempo'/></Text></TextK1> 
                            <LinkK  onClick={(e)=>{
                               setAlert({load:true});
                               setTimeout(()=>{ 
                                   setAlert({
                                    fechar:true,
                                    icon   : <Icon as={LuTrash} h={{base:'50px'}} w={{base:'50px'}} color='red.300' />, 
                                    titulo:'Cancelar alterar senha',
                                    size:'md',
                                    mensagem:' Deseja realmente deletar <b style="color:red">'+code.email+'</b>? ', 
                                    botao:[
                                        {
                                            nome:'SIM', 
                                            variant:'admin',
                                            cmd:()=>{
                                                setAlert({load:true});
                                                sendFormSocket({ modulo:'user',cmd:'deletePassword',form:{code:codeURL},callback:(result)=>{ 
                                                    setTimeout(()=>{
                                                        setAlert({exit:true});
                                               
                                                    },500)
                                                }});
                                            }
                                        },
                                        {
                                            nome:'NÃO',
                                            variant:'ghost',
                                        }
                                    ]
                                   });
                               }) 
                            }}>
                            Cancelar Alteração
                            </LinkK>
                        </Flex>  
                        <BoxDialog param={alert}  />
                    </SendForm>
                )
            break;
            case 'check':
                sendFormSocket({  modulo:'user',cmd:'changePassword',form:{chave:codeURL},callback:(result)=>{  
                    if(!result.erro){ 
                        setCmd('change');
                        setCode(result.hash); 
                    }else{
                        setCmd('error') 
                    }    
                }});  
                view =  <Flex   align='center' justify='center' >
                <Spinner   size={'xl'} color='textLight.300' _dark={{color:'textDark.300'}}/>
                <HK5 ml='15px' noOfLines='2' color='textLight.100' _dark={{color:'textDark.100'}} >Aguarde um momento...</HK5>
            </Flex>  
            break;
            case 'error':
                view =  <Stack w={'100%'} align={'flex-end'}>
                    <Flex  w={'100%'}  align='center' justify='center' > 
                        <HK5 ml='15px' noOfLines='1' color='textLight.100' _dark={{color:'textDark.100'}} px='0' >Token inválido ou expirado!</HK5>
                        
                    </Flex>
                    <Text color={'textAdmin.400'}>
                        Token não é válido, conta o suporte para gerar um novo link de alteração de senha!
                    </Text>
                    <LinkK  href="/admin/login" mr='20px'  > ir para Login</LinkK>
                </Stack>
          
            break;
        //     case 'new':case 'reload':
        //         view = (
        //             <SendForm  dados = {{modulo:'user',cmd:'lost',form:{email,code}}}  
        //                 callback={(result,json)=>{  
        //                     try { 
        //                         setEmail(json.email);
        //                         setCode(json.code);
        //                         setCmd(result.hash.cmd)
        //                     } catch (error) {
                                
        //                     } 
        //                 }}
        //                 gap={'15px'}
        //                 > 
        //                 <HK4 pb={{base:'0px',md:'5px',lg:'10px'}} textAlign='center'>Recuperar sua Conta</HK4>  
        //                 <TextK1 noOfLines="auto">Foi enviado uma chave de segurança para seu e-mail <Text as='span' fontWeight={'bold'} color='lightTema1.300'>{email}</Text>, Verifique sua caixa de entrada ou span, em seguida digite o pin abaixo:</TextK1> 
        //                 <HStack w={'full'} pt='20px'  align={'center'} justify='center'> 
        //                     <PinInput manageFocus={true} type='alphanumeric'focus onChange={(v)=>{
        //                         setCode(v); 
        //                     }}   size={'lg'}>
        //                         <PinInputField bg='lightTema1.200' color='lightTema1.300' border='2px solid' fontSize={'24px'}  _dark={{bg:'darkTema1.100'}} />
        //                         <PinInputField bg='lightTema1.200' color='lightTema1.300' border='2px solid' fontSize={'24px'}  _dark={{bg:'darkTema1.100'}}/>
        //                         <PinInputField bg='lightTema1.200' color='lightTema1.300' border='2px solid' fontSize={'24px'}  _dark={{bg:'darkTema1.100'}}/>
        //                         <PinInputField bg='lightTema1.200' color='lightTema1.300' border='2px solid' fontSize={'24px'}  _dark={{bg:'darkTema1.100'}}/>
        //                         <PinInputField bg='lightTema1.200' color='lightTema1.300' border='2px solid' fontSize={'24px'}  _dark={{bg:'darkTema1.100'}}/>
        //                         <PinInputField bg='lightTema1.200' color='lightTema1.300' border='2px solid' fontSize={'24px'}  _dark={{bg:'darkTema1.100'}}/>
        //                     </PinInput>
        //                 </HStack> 
        //                 <Flex w={'full'} pb='20px' align={'center'} justify='center' gap={'10px'}>
        //                     <LinkK href='login'>
        //                         Reenviar e-mail
        //                     </LinkK>
        //                 </Flex>
                        
        //                 <ButtonKalyst w='full'>
        //                     Validar PIN
        //                 </ButtonKalyst>
        //                 <Flex w={'full'} align={'center'} justify='center' gap={'10px'}>
        //                     <TextK1>Lembrou a senha?</TextK1> 
        //                     <LinkK href='login'>
        //                     Fazer Login
        //                     </LinkK>
        //                 </Flex>   
        //             </SendForm>
        //         )
        //     break; 
        //     default:
        //         view = (
        //             <SendForm  dados = {{modulo:'user',cmd:'lost'}}
        //                 initial = {
        //                     {
        //                         email       :   '' 
        //                     }
        //                 } 
        //                 schema={
        //                     object({ 
        //                         email:      string().required('E-mail é obrigatório!') 
        //                     })
        //                 } 
        //                 callback={(result,json)=>{  
        //                     try { 
        //                         setEmail(json.email);
        //                         setCmd(result.hash.cmd)
        //                     } catch (error) {
                                
        //                     } 
        //                 }}
        //                 gap={'15px'}
        //                 > 
        //                 <HK4 pb={{base:'0px',md:'10px',lg:'20px'}} textAlign='center'>Recuperar sua Conta</HK4>   
                
        //                 <InputK  type='email' label="Email" name="email" placeholder="Digite seu email"   focus={true} autoComplete={false}/>
                        
        //                 <ButtonKalyst w='full'>
        //                     Recuperar Conta
        //                 </ButtonKalyst>
        //                 <Flex w={'full'} align={'center'} justify='center' gap={'10px'}>
        //                     <TextK1>Lembrou a senha?</TextK1> 
        //                     <LinkK href='login'>
        //                     Fazer Login
        //                     </LinkK>
        //                 </Flex>   
        //             </SendForm>
        //         )
        //     break;
        } 
       
        return <Flex zIndex={2} w='100vw'  > 
                    <Flex zIndex={3} w='full' align={'center'} justify='center' maxW={'1200px'}  minH={'100vh'}   margin = '0 auto' >
                        <Flex w={{base:'600px',lg:'1200px'}} maxW = 'calc(100%)' minH={{base:'auto',md:'80%'}}    borderRadius={'5px'}> 
                            <Stack w={{base:'calc(100% - 40px)',lg:'50%'}} backdropFilter='blur(60px)' bg={'rgba(255,255,255,0.05)'} borderRadius={'16px'}   align={'center'} justify='center'  m={'0 auto'} > 
                                <Stack w='calc(100% - 40px)' align={'center'} justify='center' maxW={'440px'}  gap={'15px'}  py={{base:'30px'}} >
                                    <Image zIndex={1} w='296px' m={'0 auto'}  h='80px'  src={'/web/'+getWebsite().url+'/logo_menu_light.png'}    alt='login' opacity={1}  borderRadius={'5px'}/>
                                    {view}
                                </Stack> 
                            </Stack> 
                        </Flex>
                    </Flex> 
                     {redirect}
            </Flex>
    }
   
   
}